<template>
  <div>
    <div class="bg-danger text-center" v-if="modeDevelopment">
      <strong>Aplicación en desarrollo</strong>
    </div>
    <footer class="iq-footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6">
            <ul class="list-inline mb-0">
              <li class="list-inline-item">
                <a
                  style="cursor: pointer"
                  class="text-primary"
                  @click="
                    $router.push({
                      name: 'general.privacy-policies',
                    })
                  "
                  >{{ $t("app.privacy-policy") }}</a
                >
              </li>
            </ul>
          </div>
          <div class="col-lg-6 text-right">
            {{ new Date().getFullYear() }}
            <span>&copy; {{ appName }}</span> SPA
          </div>
        </div>
      </div>
    </footer>
    <!-- <FloatingButton /> -->
  </div>
</template>
<script>
import FloatingButton from "./FloatingButton.vue";
import { APPNAME } from "../../config/pluginInit";
export default {
  name: "LayoutFooter",
  components: {
    FloatingButton,
  },
  data() {
    return {
      appName: APPNAME,
    };
  },
  computed: {
    modeDevelopment() {
      return process.env.NODE_ENV == "development" ? true : false;
    },
  },
};
</script>
