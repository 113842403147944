<template>
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom">
      <div class="iq-sidebar-logo">
        <div class="top-logo">
          <router-link :to="{ path: '/' }" class="logo">
            <div class="iq-light-logo">
              <img
                src="../../../../assets/images/logo.gif"
                class="img-fluid"
                alt="logo"
              />
            </div>
            <div class="iq-dark-logo">
              <img
                src="../../../../assets/images/logo-dark.gif"
                class="img-fluid"
                alt="logo"
              />
            </div>
            <span>{{ appName }}</span>
          </router-link>
        </div>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <b-navbar-toggle target="nav-collapse" class="">
          <i class="ri-menu-3-line"></i>
        </b-navbar-toggle>
        <div
          class="iq-menu-bt align-self-center"
          v-if="$route.meta.layout !== 'two-sidebar'"
        >
          <div class="wrapper-menu" @click="sidebarMini">
            <div class="main-circle"><i class="ri-arrow-left-s-line"></i></div>
            <div class="hover-circle">
              <i class="ri-arrow-right-s-line"></i>
            </div>
          </div>
        </div>
        <b-collapse id="nav-collapse" is-nav>
          <ul class="navbar-nav ml-auto navbar-list">
            <li class="nav-item" v-nav-toggle>
              <a
                href="javascript:void(0)"
                @click="darkChange(!darkMode)"
                class="iq-waves-effect search-toggle"
              >
                <i
                  :class="darkMode ? 'ri-toggle-fill' : 'ri-toggle-line'"
                  class="h4"
                ></i>
                <i
                  class="ri-moon-fill h6 ml-1"
                  :class="darkMode ? 'text-white' : 'text-dark'"
                ></i>
              </a>
            </li>
            <li class="nav-item" v-nav-toggle>
              <a class="search-toggle iq-waves-effect language-title" href="#"
                ><img
                  :src="selectedLang.image"
                  alt="img-flaf"
                  class="img-fluid mr-1"
                  :style="
                    selectedLang.value == 'es'
                      ? 'height: 10px; width: 15px; border-radius: 2px'
                      : ''
                  "
                  style="height: 16px; width: 16px" />
                <i class="ri-arrow-down-s-line"></i
              ></a>
              <div class="iq-sub-dropdown">
                <b-overlay
                  :show="isLoading"
                  rounded="sm"
                  spinner-variant="primary"
                  spinner-type="grow"
                  spinner-small
                >
                  <a
                    class="iq-sub-card"
                    href="javascript:void(0)"
                    v-for="(lang, i) in langsOptions"
                    :key="`Lang${i}`"
                    @click="langChange(lang)"
                  >
                    <img
                      :style="
                        lang.value == 'es'
                          ? 'height: 10px; width: 15px; border-radius: 2px'
                          : ''
                      "
                      :src="lang.image"
                      alt="img-flaf"
                      class="img-fluid mr-2"
                    />{{ lang.title }}
                  </a>
                </b-overlay>
              </div>
            </li>
          </ul>
        </b-collapse>
        <ul class="navbar-list" v-if="user">
          <li class="rounded" v-nav-toggle>
            <a
              href="#"
              class="search-toggle iq-waves-effect d-flex align-items-center bg-primary rounded"
            >
              <img
                :src="
                  user.avatar
                    ? user.avatar
                    : require('@/assets/images/user/1.jpg')
                "
                class="img-fluid rounded mr-3"
                alt="user"
              />
              <div class="caption">
                <h6 class="mb-0 line-height text-white">{{ user.name }}</h6>
                <span class="font-size-12 text-white">Rut: {{ user.rut }}</span>
              </div>
            </a>
            <div class="iq-sub-dropdown iq-user-dropdown">
              <div class="iq-card shadow-none m-0">
                <div class="iq-card-body p-0">
                  <div class="bg-primary p-3">
                    <h5 class="mb-0 text-white line-height">
                      {{ $t("sidebar.welcome") }} 🖐️
                    </h5>
                  </div>

                  <router-link
                    :to="{ name: 'user.edit' }"
                    class="iq-sub-card iq-bg-primary-hover"
                  >
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-primary">
                        <i class="ri-user-settings-fill"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0">
                          {{ $t("nav.user.profileTitle") }}
                        </h6>
                        <p class="mb-0 font-size-12">
                          {{ $t("nav.user.profileEditSub") }}
                        </p>
                      </div>
                    </div>
                  </router-link>
                  <a
                    v-if="collegesSessionUser.length > 1"
                    class="iq-sub-card iq-bg-primary-hover"
                    @click="$refs.modalCollageAuth.show(collegeAuthUser)"
                    style="cursor: pointer"
                  >
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-primary">
                        <i class="ri-community-fill"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0">{{ $t("sidebar.change-college") }}</h6>
                        <p class="mb-0 font-size-12">
                          {{ $t("profile.actual") }}:
                          {{
                            collegeAuthUser
                              ? collegeAuthUser.name
                              : $t("sidebar.no-college")
                          }}
                        </p>
                      </div>
                    </div>
                  </a>
                  <div class="d-inline-block w-100 text-center p-3">
                    <a
                      class="btn btn-primary dark-btn-primary"
                      href="javascript:void(0)"
                      @click="logout"
                      role="button"
                    >
                      <span v-if="isLoading">
                        <b-spinner small type="grow"></b-spinner>
                        {{ $t("app.loading") }}...
                      </span>
                      <span v-else>
                        {{ $t("sidebar.logout")
                        }}<i class="ri-login-box-line ml-2"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
    <modal-form ref="modalCollageAuth" />
    <modalNotifications ref="notificationAll" />
    <b-modal id="bv-modal-notification-show" hide-footer>
      <template #modal-title> {{ $t("sidebar.notifications") }} </template>
      <div class="d-block text-center">
        <h3>{{ notificationShow.title }}</h3>
        {{ notificationShow.message }}
      </div>
      <b-button
        class="mt-3"
        block
        size="sm"
        @click="$bvModal.hide('bv-modal-notification-show')"
        >{{ $t("app.close") }}</b-button
      >
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import GlobalSearch from "../../search/GlobalSearch";
import { core, APPNAME } from "@/config/pluginInit";
import langChange from "@/Mixins/langchange";
import Lottie from "@/components/core/lottie/Lottie";
import modalForm from "../../../Modal/CollageSesion/collageSesionAuth.vue";
import modalNotifications from "../../../Modal/Notifications/AllNotifications.vue";
import moment from "moment";
export default {
  name: "HeaderStyle",
  mixins: [langChange],
  components: {
    GlobalSearch,
    Lottie,
    modalForm,
    modalNotifications,
  },
  data() {
    return {
      moment,
      appName: APPNAME,
      globalSearch: "",
      notificationShow: {},
      rtl: false,
      dark: JSON.parse(localStorage.getItem("dark")),
    };
  },
  async created() {
    await this.darkChange(this.dark);
    if (!this.horizontal) {
      document.addEventListener("click", this.closeSearch, true);
    }
    this.fetchNotifications();
  },
  methods: {
    sidebarMini() {
      core.triggerSet();
    },
    async showDetailsNotification(data) {
      this.notificationShow = data;
      const resp = await this.markNotifications({ notifications: [data.id] });
      if (resp.status === 200) {
        this.$bvModal.show("bv-modal-notification-show");
      }
    },
    async logout() {
      try {
        const resp = await this.logoutSession("logout");
        if (resp.status === 204) {
          this.$router.push("/auth/login");
        }
      } catch (error) {
        console.log(error);
      }
    },
    openSearch() {
      this.showSearch = true;
      this.showMenu = "iq-show";
      this.globalSearch = "";
      if (document.getElementById("searchbox-datalink") !== null) {
        document
          .getElementById("searchbox-datalink")
          .classList.add("show-data");
      }
    },
    closeSearch(event) {
      const classList = event.target.classList;
      if (
        !classList.contains("searchbox") &&
        !classList.contains("search-input")
      ) {
        this.removeClass();
      }
    },
    removeClass() {
      this.showSearch = false;
      this.showMenu = "";
      this.globalSearch = "";
      if (
        document.getElementById("searchbox-datalink") !== null &&
        document.getElementById("searchbox-datalink") !== undefined
      ) {
        document
          .getElementById("searchbox-datalink")
          .classList.remove("show-data");
      }
    },
    ...mapActions({
      langChangeState: "Setting/setLangAction",
      rtlAdd: "Setting/setRtlAction",
      rtlRemove: "Setting/removeRtlAction",
      theUpdateLang: "updateLang",
      logoutSession: "logout",
      fetchNotifications: "fetchNotifications",
      markNotifications: "markNotifications",
      modeChange: "Setting/layoutModeAction",
    }),
    darkChange(mode) {
      this.dark = mode;
      this.modeChange({ rtl: this.rtl, dark: this.dark });
    },
  },

  computed: {
    ...mapGetters({
      user: "user",
      isLoading: "isLoading",
      getNotifications: "getNotifications",
      collegeAuthUser: "collageAuthUser",
      collegesSessionUser: "collageSession",
      darkMode: "Setting/darkModeState",
      langsOptions: "Setting/langOptionState",
      selectedLang: "Setting/langState",
    }),

    notification() {
      return this.getNotifications
        .filter((item) => item.read === 0)
        .map((item) => ({
          id: item.id,
          name: item.title,
          date: moment(item.date, "YYYY-MM-DD HH:mm").startOf("hour").fromNow(),
          message: item.message,
        }));
    },
  },
};
</script>
