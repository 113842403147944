import { core } from "@/config/pluginInit";
import { mapGetters } from "vuex";
export default {
  mounted() {
    this.$i18n.locale = this.user?.locale;
    this.langChangeState(
      this.arrayLang.find((item) => item.value == this.user?.locale)
    );
  },
  data() {
    return {
      arrayLang: [
        {
          title: "Español",
          value: "es",
          image: require("@/assets/images/small/es.png"),
        },
        {
          title: "English",
          value: "en",
          image: require("@/assets/images/small/flag-01.png"),
        },
      ],
    };
  },
  methods: {
    async langChange(lang) {
      const resp = await this.theUpdateLang(lang.value);
      if (resp?.status == 201) {
        core.showSnackbar("success", resp.data.message);
      }
      this.langChangeState(lang);

      this.$i18n.locale = resp.data.locale;
      document.getElementsByClassName("iq-show")[0].classList.remove("iq-show");
    },
  },
};
