<template>
  <b-modal id="modal-change-collage-session" hide-footer scrollable>
    <template #modal-header>
      <h4>
        {{ $t("sidebar.change-college") }}
      </h4>
    </template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="new-user-info">
          <b-row class="justify-content-md-center">
            <b-form-group
              class="col-md-12"
              :label="$t('sidebar.colleges')"
              label-for="education"
            >
              <ValidationProvider
                :name="$t('sidebar.colleges')"
                rules="required"
                v-slot="{ errors }"
              >
                {{ data.collageAuthUser }}
                <v-select
                  v-model="data.college_id"
                  :placeholder="$t('sidebar.colleges')"
                  :options="collageSession"
                  label="name"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                  <template #no-options="{}">
                    {{ $t("app.not-found") }}...
                  </template>
                </v-select>
                <div v-if="errors[0]">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-row>
          <hr />
          <b-button
            :disabled="isLoading"
            variant="success"
            class="float-right"
            type="submit"
          >
            <span v-if="isLoading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t("app.loading") }}...
            </span>
            <span v-else>
              {{ $t("app.save") }}
            </span>
          </b-button>
          <b-button
            class="float-right mr-1"
            variant="outline-danger"
            @click="$bvModal.hide('modal-change-collage-session')"
            >{{ $t("app.cancel") }}</b-button
          >
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "modalForm",
  created() {
    core.index();
    this.collageSesion();
  },
  data() {
    return {
      data: {
        college_id: null,
      },
    };
  },
  watch: {
    collageAuthUser() {
      this.data.college_id = this.collageAuthUser;
    },
  },
  methods: {
    ...mapActions({
      collageSesion: "collageSesion",
      updateCollageSesion: "updateCollageSesion",
    }),
    async show(item) {
      this.reserForm();
      if (item) {
        this.data = {
          college_id: item,
        };
      }
      this.$bvModal.show("modal-change-collage-session");
    },
    async onSubmit() {
      let payload = {
        college_id: this.data.college_id.id,
      };
      const resp = await this.updateCollageSesion(payload);
      this.$bvModal.hide("modal-change-collage-session");
      if (resp.status === 201 || resp.status === 200) {
        location.reload();
        this.$router.go(0);
        core.showSnackbar("success", this.$t("sidebar.change-sesion-collage"));
      }
      this.reserForm();
    },
    reserForm() {
      this.data = {
        id: null,
        name: null,
        initials: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading",
      getPeriods: "getPeriods",
      getGrades: "getGrades",
      collageSession: "collageSession",
    }),
  },
};
</script>
