<template>
  <b-modal size="lg" id="modal-notification-all" hide-footer scrollable>
    <template #modal-header>
      <h4>
        {{ $t("sidebar.notifications") }}
      </h4>
    </template>
    <div>
      <div class="scroll bg-transparent">
        <div
          v-if="notificationsLoading"
          class="d-flex justify-content-center mb-3 mt-5"
        >
          <b-spinner
            style="width: 3rem; height: 3rem"
            label="Large Spinner"
            type="grow"
            variant="primary"
          ></b-spinner>
          <span class="h4 mt-2 text-primary"> {{ $t("app.loading") }}... </span>
        </div>
        
        <TimeLine v-else :items="timelineItems" style="width: 380px" />
        <div class="text-center" v-if="timelineItems.length <= 0">
          <h4>{{ $t("notifications.not-notifications") }}</h4>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import "moment/locale/es"; // without this line it didn't work
moment.locale("es");
export default {
  name: "modalForm",
  created() {
    core.index();
    this.fetchNotifications();
  },
  data() {
    return {
      data: {
        college_id: null,
      },
    };
  },

  methods: {
    ...mapActions({
      collageSesion: "collageSesion",
      updateCollageSesion: "updateCollageSesion",
      fetchNotifications: "fetchNotifications",
    }),
    async show() {
      this.$bvModal.show("modal-notification-all");
    },
    setBg() {
      return "#" + Math.floor(Math.random() * 16777215).toString(16);
    },
    async onSubmit() {
      let payload = {
        college_id: this.data.college_id.id,
      };
      const resp = await this.updateCollageSesion(payload);
      this.$bvModal.hide("modal-notification-all");
      if (resp.status === 201 || resp.status === 200) {
        location.reload();
        this.$router.go(0);
        core.showSnackbar("success", this.$t("sidebar.change-sesion-collage"));
      }
    },
  },
  computed: {
    ...mapGetters({
      getNotifications: "getNotifications",
      notificationsLoading: "notificationsLoading",
    }),
    timelineItems() {
      return this.getNotifications.map((item) => ({
        id: item.id,
        color: this.setBg(),
        title: item.title,
        right: moment(item.date).locale("es").format("LLLL") + " hrs.",
        description: item.message,
        child: {
          type: "img",
          items: [],
        },
      }));
    },
  },
};
</script>
<style>
.scroll {
  max-height: 610px;
  overflow-y: auto;
}
</style>
